import TicketStatus from "../../enums/TicketStatus";
import ICreateTicket from "../../interfaces/ICreateTicket";
import IAttachment from "../../models/IAttachment";
import IComment from "../../models/IComment";
import IMessage from "../../models/IMessage";
import ITicket from "../../models/ITicket";
import networkService from "../NetworkService";

/**
 * @description Service for managing helpdesk tickets
 */
class TicketService {

    /**
     * @description Fetches a helpdesk ticket
     * @param {number} ticketId The ID of the ticket to be returned
     */
    public async getTicket(ticketId: number): Promise<ITicket> {
        const result = await networkService.get<ITicket>(`ticket/${ticketId}`);

        if (result.isSuccessful) {
            return result.data as ITicket;
        }

        throw result.data;
    }

    /**
     * @description Fetch all tickets for the selected status
     * @param {TicketStatus} status The status of the tickets to be returned
     */
    public async getTickets(status: TicketStatus = TicketStatus.OPEN): Promise<ITicket[]> {
        const result = await networkService.get<ITicket[]>(`tickets/${status}`);

        if (result.isSuccessful) {
            return result.status !== 204
                ? result.data as ITicket[]
                : [];
        }

        throw result.data;
    }

    /**
     * @description Count all tickets for the selected status
     * @param {TicketStatus} status The status of the tickets to be counted
     */
    public async countTickets(status: TicketStatus = TicketStatus.OPEN): Promise<number> {
        const result = await networkService.get<number>(`tickets/${status}/count`);

        if (result.isSuccessful) {
            return result.data as number;
        }

        throw result.data;
    }

    /**
     * @description Creates a new helpdesk ticket
     * @param {ICreateTicket} ticket The ticket to be created
     */
    public async createTicket(ticket: ICreateTicket): Promise<number> {
        const result = await networkService.put(`ticket`, ticket);

        if (result.isSuccessful) {
            return result.data as number;
        }

        throw result.data;
    }

    /**
     * @description Fetches all attachments for a helpdesk ticket
     * @param {number} ticketId The ID of the ticket to fetch attachments for
     */
    public async getAttachments(ticketId: number): Promise<IAttachment[]> {
        const result = await networkService.get<IAttachment[]>(`ticket/${ticketId}/attachments`);

        if (result.isSuccessful) {
            return result.status !== 204
                ? result.data as IAttachment[]
                : [];
        }

        throw result.data;
    }

    /**
     * @description Fetches all attachments for a helpdesk ticket
     * @param {number} ticketId The ID of the ticket the attachment belongs to
     * @param {number} attachmentId The ID of the attachment to be downloaded
     */
    public async downloadAttachment(ticketId: number, attachmentId: number): Promise<Blob> {
        try {
            return await networkService.download(`ticket/${ticketId}/attachment/${attachmentId}`);
        } catch {
            throw new Error("Failed to download attachment");
        }
    }

    /**
     * @description Uploads an attachment to a helpdesk ticket
     * @param {number} ticketId The ID of the ticket the attachment wil be uploaded to
     * @param {File} file The attachment to be uploaded
     */
    public async uploadAttachment(ticketId: number, file: File): Promise<boolean> {
        try {
            const form = new FormData();
            form.append("file", file);

            const result = await networkService.put(`ticket/${ticketId}/attachment`, form);

            if (result.isSuccessful) {
                return true;
            }
    
            throw result.data;
        } catch {
            throw new Error("Failed to download attachment");
        }
    }

    /**
     * @description Fetches all comments for a helpdesk ticket
     * @param {number} ticketId The ID of the ticket to fetch comments for
     */
    public async getComments(ticketId: number): Promise<IComment[]> {
        const result = await networkService.get<IComment[]>(`ticket/${ticketId}/comments`);

        if (result.isSuccessful) {
            return result.status !== 204
                ? result.data as IComment[]
                : [];
        }

        throw result.data;
    }

    /**
     * @description Posts a comment to a helpdesk ticket
     * @param {number} ticketId The ID of the ticket to post a comment to
     * @param {string} comment The comment to be posted
     */
    public async postComment(ticketId: number, comment: string): Promise<boolean> {
        const result = await networkService.put(`ticket/${ticketId}/comment`, comment);
        return result.isSuccessful;
    }

    /**
     * @description Fetches all messages for a helpdesk ticket
     * @param {number} ticketId The ID of the ticket to fetch messages for
     */
    public async getMessages(ticketId: number): Promise<IMessage[]> {
        const result = await networkService.get<IMessage[]>(`ticket/${ticketId}/communication`);

        if (result.isSuccessful) {
            return result.status !== 204
                ? result.data as IMessage[]
                : [];
        }

        throw result.data;
    }
}

const ticketService = new TicketService();
export default ticketService;