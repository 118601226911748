import { Container, Nav, Navbar, NavbarCollapse } from "react-bootstrap";
import { NavLink } from "./NavLink";
import { Collapse } from "./Collapse";
import styles from './Sidebar.module.scss';
import { useTranslation } from "react-i18next";
import Contact from "./Contact";
import AccountMenu from "./AccountMenu";
import { Icon } from "../icon/Icon";
import ClientDropdown from "./ClientDropdpwn";
import accountService from "../../services/AccountService";

/**
 * @description Properties for the Sidebar component
 */
interface ISidebarProps {
    logout: () => void;
}

const Logo = () => {
    const { t } = useTranslation();

    const logoUrl = "/img/dolphin-solutions_logo_blue-black.png";
    return (<img src={logoUrl} alt={t("dolphin.company.name")} className={styles.logo} />);
};

export default function Sidebar(props: ISidebarProps) {
    const { t } = useTranslation();

    const roles = accountService.getRoles();

    return (
        <Navbar className='navbar-vertical fixed-start' expand='md' id='sidebar'>
            <Container fluid>
                <Navbar.Toggle aria-controls="sidebarCollapse" className="border-0 ms-3">
                    <Icon iconName="CaretDown" />
                </Navbar.Toggle>
                <Navbar.Brand href="/" className='pt-0' children={<Logo />} />

                <NavbarCollapse id="sidebarCollapse">
                    { roles.includes("Administrator") }
                    <div className={`${styles.menu} navbar-nav`}>
                        <NavLink path="/" label={t("navigation.home")} icon='House' />
                        <Nav.Item>
                            <NavLink path="#sidebarHelpdesk" label={t("navigation.helpdesk.name")} icon='Headset' />
                            <Collapse id="sidebarHelpdesk">
                                <Nav.Item>
                                    <NavLink path="/helpdesk/ticket" label={t("navigation.helpdesk.new")} />
                                    <NavLink path="#sidebarTickets" label={t("navigation.helpdesk.tickets.name")} />
                                    <Collapse id="sidebarTickets">
                                        <NavLink path="/helpdesk/tickets/open" label={t("navigation.helpdesk.tickets.open")} />
                                        <NavLink path="/helpdesk/tickets/open/queue" label={t("navigation.helpdesk.tickets.queue")} />
                                        {
                                            (roles.includes("Administrator") || roles.includes("MaceHelpdesk")) &&
                                            <NavLink path="/helpdesk/tickets/open/my" label={t("navigation.helpdesk.tickets.my")} />
                                        }
                                        {
                                            roles.includes("MaceHelpdesk") &&
                                            <NavLink path="/helpdesk/tickets/open/powerbi" label={t("navigation.helpdesk.tickets.pbi")} />
                                        }
                                        <NavLink path="/helpdesk/tickets/onhold" label={t("navigation.helpdesk.tickets.hold")} />
                                        <NavLink path="/helpdesk/tickets/closed/month" label={t("navigation.helpdesk.tickets.closedInMonth")} />
                                        <NavLink path="/helpdesk/tickets/closed" label={t("navigation.helpdesk.tickets.closed")} />
                                        <NavLink path="/helpdesk/tickets/archived" label={t("navigation.helpdesk.tickets.archived")} />
                                    </Collapse>
                                </Nav.Item>
                            </Collapse>
                        </Nav.Item>

                        {
                        roles.includes("Administrator") && (
                            <Nav.Item>
                                <NavLink path="#sidebarAdmin" label={t("navigation.administration.name")} icon='Gear' />
                                <Collapse id="sidebarAdmin">
                                    <Nav.Item>
                                        <NavLink path="#sidebarUsers" label={t("navigation.administration.users.name")} />
                                        <Collapse id="sidebarUsers">
                                            <NavLink path="/administration/users" label={t("navigation.administration.users.all")} />
                                            <NavLink path="/administration/user" label={t("navigation.administration.users.create")} />
                                        </Collapse>
                                    </Nav.Item>
                                </Collapse>
                            </Nav.Item>
                            )
                        }
                        
                        {
                            /*
                        <Nav.Item>
                            <NavLink path="#sidebarProjects" label={t("navigation.projects.name")} icon='Kanban' />
                            <Collapse id="sidebarProjects">
                                <NavLink path="/projects/open" label={t("navigation.projects.projects.open")} />
                                <NavLink path="/projects/complete" label={t("navigation.projects.projects.completed")} />
                            </Collapse>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink path="#sidebarTasks" label={t("navigation.tasks.name")} icon='ClipboardCheck' />
                            <Collapse id="sidebarTasks">
                                <NavLink path="/tasks/open" label={t("navigation.tasks.tasks.open")} />
                                <NavLink path="/tasks/complete/month" label={t("navigation.tasks.tasks.completedInMonth")} />
                                <NavLink path="/tasks/complete" label={t("navigation.tasks.tasks.completed")} />
                            </Collapse>
                        </Nav.Item>
                        <NavLink path="/wiki" label={t("navigation.wiki.name")} icon='Book' />
                        */
                        }
                    </div>
                    <div className="mt-lg-auto">
                        <div className="navbar-user d-flex" id="sidebarUser">
                            <Contact />
                            <AccountMenu logout={props.logout} />
                        </div>
                    </div>
                </NavbarCollapse>
            </Container>
        </Navbar>
    );
}