import { Button, Card, Col, Collapse, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Icon } from "../components/icon/Icon";
import StatCard from "../components/cards/statCard/StatCard";
import Status from "../enums/Status";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ticketService from "../services/helpdesk/TicketService";
import TicketStatus from "../enums/TicketStatus";
import accountService from "../services/AccountService";
import ITicket from "../models/ITicket";

export default function Helpdesk() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [openTickets, setOpenTickets] = useState<ITicket[]>([]);
    const [openStatus, setOpenStatus] = useState<Status>(Status.LOADING);
    const [closedInMonthStatus, setClosedInMonthStatus] = useState<Status>(Status.LOADING);
    const [onHoldStatus, setOnHoldStatus] = useState<Status>(Status.LOADING);
    const [hoursStatus, setHoursStatus] = useState<Status>(Status.LOADING);
    const [myStatus, setMyStatus] = useState<Status>(Status.LOADING);
    const [unassignedStatus, setUnassignedStatus] = useState<Status>(Status.LOADING);
    const [clientTicketData, setClientTicketData] = useState<{ [client: string]: any }>({}); // For each client's ticket data
    const [openClient, setOpenClient] = useState<string | null>(null);

    const [openCount, setOpenCount] = useState<number>(0);
    const [closedInMonthCount, setClosedInMonthCount] = useState<number>(0);
    const [onHoldCount, setOnHoldCount] = useState<number>(0);
    const [hoursCount, setHoursCount] = useState<number>(0);
    const [myCount, setMyCount] = useState<number>(0);
    const [unassignedCount, setUnassignedCount] = useState<number>(0);

    const handleToggle = (client: string) => {
        setOpenClient(prev => (prev === client ? null : client)); // Toggle the opened client
    };

    useEffect(() => {
        // Fetch open tickets and set both the open tickets and the open count
        setOpenTicketsCount(setOpenTickets, setOpenCount, setOpenStatus);
    }, []);
    
    useEffect(() => setClosedInMonthTicketsCount(setClosedInMonthCount, setClosedInMonthStatus), []);
    useEffect(() => setOnHoldTicketsCount(setOnHoldCount, setOnHoldStatus), []);
    useEffect(() => setHours(setHoursCount, setHoursStatus), []);
    
    useEffect(() => {
        const fetchTicketsAndAccount = async () => {
            try {
                // Fetch open tickets
                const tickets = await ticketService.getTickets(TicketStatus.OPEN);
                setOpenTickets(tickets);
                setOpenCount(tickets.length);
                setOpenStatus(Status.LOAD_SUCCESS);

                // Filter my tickets
                const account = await accountService.getAccount();
                const myTickets = filterMyTickets(tickets, account.username);
                setMyCount(myTickets.length);
                setMyStatus(Status.LOAD_SUCCESS);

                // Filter unassigned tickets
                const unassignedTickets = filterUnassignedTickets(tickets);
                setUnassignedCount(unassignedTickets.length);
                setUnassignedStatus(Status.LOAD_SUCCESS);

                // Count tickets by client
                const clientCounts = countTicketsByClient(tickets);
                setClientTicketData(clientCounts);
            } catch (error) {
                setOpenStatus(Status.LOAD_ERROR);
                setMyStatus(Status.LOAD_ERROR);
                setUnassignedStatus(Status.LOAD_ERROR);
            }
        };

        fetchTicketsAndAccount(); // Call async function
    }, []);

    const roles = accountService.getRoles();

    const open =
        openStatus === Status.LOADING
            ? t("general.wait")
            : openStatus === Status.LOAD_SUCCESS
                ? openCount
                : "ERR";

    const closed =
        closedInMonthStatus === Status.LOADING
            ? t("general.wait")
            : closedInMonthStatus === Status.LOAD_SUCCESS
                ? closedInMonthCount
                : "ERR";

    const onHold =
        onHoldStatus === Status.LOADING
            ? t("general.wait")
            : onHoldStatus === Status.LOAD_SUCCESS
                ? onHoldCount
                : "ERR";

    const mine =
        myStatus === Status.LOADING
            ? t("general.wait")
            : myStatus === Status.LOAD_SUCCESS
                ? myCount
                : "ERR";

    const unassigned =
        unassignedStatus === Status.LOADING
            ? t("general.wait")
            : unassignedStatus === Status.LOAD_SUCCESS
                ? unassignedCount
                : "ERR";

    const hours =
        hoursStatus === Status.LOADING
            ? t("general.wait")
            : hoursStatus === Status.LOAD_SUCCESS
                ? hoursCount
                : "ERR";

    const tooltip = <Tooltip id="hd-add-ticket">{t("helpdesk.log")}</Tooltip>;

    return (
        <>
            <Row>
                <Col>
                    <h2>{t("helpdesk.titles.hd")}</h2>
                </Col>
                <Col xs="auto">
                    <OverlayTrigger overlay={tooltip} placement="left">
                        <Button variant='primary' size="sm" className='btn-rounded-circle'
                                onClick={() => navigate("/helpdesk/ticket")}>
                            <Icon iconName='Plus' />
                        </Button>
                    </OverlayTrigger>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <StatCard title="Currently open tickets"
                        value={open}
                        icon="List"
                        link='/helpdesk/tickets/open' />
                </Col><Col xs={12} md={6}>
                        <StatCard title="Tickets closed this month"
                            value={closed}
                            icon="Check"
                            link='/helpdesk/tickets/closed/month' />
                    </Col><Col xs={12} md={6}>
                        <StatCard title="Tickets on hold"
                            value={onHold}
                            icon="Pause"
                            link='/helpdesk/tickets/onhold' />
                    </Col>
                    
                    { !roles.includes("Administrator") && (
                        <Col xs={12} md={6}>
                            <StatCard 
                                title="Hours worked this month"
                                value={hours}
                                icon="Hourglass" 
                            />
                        </Col>
                    )}

                    { roles.includes("Administrator") && (
                        <><Col xs={12} md={6}>
                        <StatCard
                            title="Tickets assigned to me"
                            value={mine}
                            icon="Pause" />
                    </Col>
                    <Col xs={12} md={6}>
                            <StatCard
                                title="Tickets in queue"
                                value={unassigned}
                                icon="Pause" />
                        </Col></>
                    )}
            </Row>

            {roles.includes("Administrator") && Object.entries(clientTicketData).map(([client, data]) => (
                 <Card key={client} style={{ marginBottom: '1rem' }}>
                     <Card.Header
                    onClick={() => handleToggle(client)}
                    style={{
                        cursor: 'pointer',
                        backgroundColor: openClient === client ? '#007bff' : '#fff',
                        color: openClient === client ? '#fff' : '#000',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <h3 style={{ margin: 0 }}>{client}</h3>
                    <span style={{ fontSize: '1rem' }}>
                            {openClient === client ? '▲' : '▼'}
                        </span>
                </Card.Header>
                    <Collapse in={openClient === client}>
                    <div>
                    <Card.Body>
                    <Row>
                        <Col xs={12} md={4}>
                            <StatCard title="Currently open tickets" value={data.openCount} icon="List" link={`/helpdesk/tickets/open`} />
                        </Col>
                        <Col xs={12} md={4}>
                            <StatCard title="Tickets assigned to me" value={data.myCount} icon="Pause" link={`/helpdesk/tickets/open/my`} />
                        </Col>
                        <Col xs={12} md={4}>
                            <StatCard title="Tickets in queue" value={data.unassignedCount} icon="Pause" link={`/helpdesk/tickets/open/queue`} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            <StatCard title="Tickets on hold" value={data.onHoldCount} icon="Pause" link={`/helpdesk/tickets/onhold`} />
                        </Col>
                    </Row>
                    </Card.Body>
                    </div>
                </Collapse>
            </Card>
            ))}
        </>
    );
}

function setOpenTicketsCount(
    setTickets: Dispatch<SetStateAction<ITicket[]>>,
    setCount: Dispatch<SetStateAction<number>>,
    setStatus: Dispatch<SetStateAction<Status>>
) {
    setStatus(Status.LOADING);

    // Call the service to get the tickets, handle the promise correctly
    ticketService.getTickets(TicketStatus.OPEN)
        .then(tickets => {
            setTickets(tickets);  // Set open tickets array
            setCount(tickets.length);  // Set open ticket count based on array length
            setStatus(Status.LOAD_SUCCESS);
        })
        .catch(() => setStatus(Status.LOAD_ERROR));
}

function filterMyTickets(openTickets: ITicket[], username: string) {
    return openTickets.filter(t => t.assignee?.emailAddress.toLowerCase() === username.toLowerCase());
}

function filterUnassignedTickets(openTickets: ITicket[]) {
    return openTickets.filter(t => !t.assignee); // Assuming `assignee` is null or undefined for unassigned tickets
}

function countTicketsByClient(openTickets: ITicket[]) {
    const counts: { [client: string]: { openCount: number, closedCount: number, onHoldCount: number, myCount: number, unassignedCount: number, hoursCount: number } } = {};
    
    openTickets.forEach(ticket => {
        const client = ticket.client || 'Unassigned'; // Handle case if client is null or undefined
        if (!counts[client]) {
            counts[client] = {
                openCount: 0,
                closedCount: 0,
                onHoldCount: 0,
                myCount: 0,
                unassignedCount: 0,
                hoursCount: 0 // Initialize all counts
            };
        }

        // Increment counts accordingly
        counts[client].openCount += 1;
        // Update closedCount, onHoldCount, myCount, and unassignedCount based on your criteria
        // For example, if you have logic to determine if the ticket is closed or on hold, implement it here.
    });

    // Update the closedCount and onHoldCount logic here if you fetch these counts from the API or calculate them from tickets
    // Similarly, update myCount and unassignedCount based on the ticket's assignee property

    return counts;
}

function setClosedInMonthTicketsCount(
    setCount: Dispatch<SetStateAction<number>>,
    setStatus: Dispatch<SetStateAction<Status>>
) {
    setStatus(Status.LOADING);

    try {
        ticketService.countTickets(TicketStatus.CLOSED_IN_MONTH)
            .then(count => {
                setCount(count);
                setStatus(Status.LOAD_SUCCESS);
            })
            .catch(() => setStatus(Status.LOAD_ERROR));
    } catch {
        setStatus(Status.LOAD_ERROR);
    }
}

function setOnHoldTicketsCount(
    setCount: Dispatch<SetStateAction<number>>,
    setStatus: Dispatch<SetStateAction<Status>>
) {
    setStatus(Status.LOADING);

    try {
        ticketService.countTickets(TicketStatus.ON_HOLD)
            .then(count => {
                setCount(count);
                setStatus(Status.LOAD_SUCCESS);
            })
            .catch(() => setStatus(Status.LOAD_ERROR));
    } catch {
        setStatus(Status.LOAD_ERROR);
    }
}

function setHours(
    setHours: Dispatch<SetStateAction<number>>,
    setStatus: Dispatch<SetStateAction<Status>>
) {
    setStatus(Status.LOADING);

    try {
        setTimeout(() => {
            setHours(43);
            setStatus(Status.LOAD_SUCCESS);
        }, 250)
    } catch {
        setStatus(Status.LOAD_ERROR);
    }
}